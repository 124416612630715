export function checkCookieExistence(cookieName) {
    // Split the cookies into an array
    const cookies = document.cookie.split(';');

    // Iterate through the cookies and check if the specified cookie name exists
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();

        // Check if the cookie starts with the specified name
        if (cookie.startsWith(`${cookieName}=`)) {
            return true; // Cookie found
        }
    }

    return false; // Cookie
}

export function getCookieValue(cookieName) {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();

        // Check if the cookie starts with the specified name
        if (cookie.startsWith(name)) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return null; // Cookie not found
}