import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';

import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Box,
    Button,
    Dialog,
    DialogActions,
    MenuItem,
    InputLabel,
    Select,
    FormControl,
    DialogContentText,
    DialogTitle,
    DialogContent

} from '@mui/material';
import { styled } from "@mui/material/styles";
// import axios from 'axios';
import config from '../config';
import { axiosObj as axios } from '../utils/axios';


export default function Course({ user }) {

    const [fetchAgain, setFetchAgain] = useState("ok");

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    let { courseSlug } = useParams();
    const [course, setCourse] = useState();
    const [courseContents, setCourseContents] = useState();

    const getCourse = () => {
        axios.get(config.BASE_URL + "course/" + courseSlug).then(response => {
            let data = response.data;

            console.log(data);

            setCourse(data);
            setCourseContents(data.courseContent);
        })
    }

    useEffect(() => {
        if (courseSlug) {
            getCourse();
        }
    }, [courseSlug, fetchAgain])

    // to create course content
    const [contents, setContents] = useState([]);
    const [openCourseContent, setOpenCourseContent] = useState(false);

    const [selectedContentId, setSelectedContentId] = useState();

    const getContents = () => {
        axios.get(config.BASE_URL + "content", {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => {
            let data = response.data;

            setContents(data);
        })
    }

    useEffect(() => {
        getContents()
    }, [])

    const createCourseContent = () => {
        // make http request
        axios.post(`${config.BASE_URL}course/${course._id}/course-content`, {
            content: selectedContentId,
            sequence: course.courseContent.length
        }, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => {
            let data = response.data;

            setOpenCourseContent(false);
            setFetchAgain(fetchAgain + "k");
        })
    }


    // to implement drag and drop for to arange content sequence
    // followed from: https://rootstack.com/en/blog/how-do-i-use-drag-and-drop-react

    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    const drop = (e) => {
        const copyListItems = [...courseContents];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setCourseContents(copyListItems);
    };
    const updateCourseContentSequence = () => {
        let newCourseContent = [];
        courseContents.forEach((element, index) => {
            newCourseContent.push({
                id: element._id,
                newSequence: index,
                preSequence: element.sequence
            })
        });

        console.log(newCourseContent)

        // update Course content Sequence
        axios.patch(`${config.BASE_URL}course/${course._id}/course-content`, {
            courseContent: newCourseContent,
        }, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => {
            let data = response.data;

            setFetchAgain(fetchAgain + "k");
        })
    }


    return (
        <React.Fragment>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <div style={{ marginBottom: '10px' }}>
                    {
                        course && <Card>
                            <CardHeader title={course.title} />
                            <CardContent>
                                ID: {course._id} <br />
                                Title: {course.title} <br />
                                Description: {course.description} <br />
                                Slug: {course.slug} <br />
                            </CardContent>
                        </Card>
                    }
                </div>
                <div style={{ margin: '10px' }}>
                    {
                        course &&
                        courseContents.map((item, index) => {
                            return (
                                <div
                                    onDragStart={(e) => dragStart(e, index)}
                                    onDragEnter={(e) => dragEnter(e, index)}
                                    onDragEnd={drop}
                                    draggable
                                    style={{ marginBottom: '10px', cursor: 'pointer' }}
                                    key={item._id}>
                                    <Card>
                                        <CardHeader title={index + "(" + item.sequence +"). [" + item.content.contentType + "] " + item.content.title} />
                                        <CardContent>
                                            Link: <a href={item.content.link} target="_blank">Link</a> <br />
                                            Description: {item.content.description}
                                        </CardContent>
                                    </Card>
                                </div>

                            )
                        })
                    }
                    <Button fullWidth onClick={() => {
                        setOpenCourseContent(true)
                    }} variant="contained">Add Course Content</Button>
                </div>
                <Button fullWidth onClick={() => {
                    updateCourseContentSequence(true)
                }} variant="contained">Update Course Content Sequence</Button>
            </Box>

            {/* Create Course content Dialog */}
            <Dialog variant="standard" fullWidth open={openCourseContent} onClose={() => { setOpenCourseContent(false) }}>
                <DialogTitle>Add Content to Course</DialogTitle>
                <DialogContent variant="standard" fullWidth>
                    <DialogContentText>
                        Add a Content to the Course
                    </DialogContentText>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="demo-select-small">Content Id</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            onChange={(e) => setSelectedContentId(e.target.value)}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {contents.map((item, index) => {
                                return (
                                    <MenuItem key={item._id} value={item._id}>{index}. [{item.contentType}] {item.title}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenCourseContent(false) }}>Cancel</Button>
                    <Button onClick={createCourseContent}>Add Content to Course</Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}
