import React, { useState } from 'react'
import Button from "@mui/material/Button";
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Course({ setOpenCourse, courses }) {
    let navigate = useNavigate();

    const goToCourse = (slug) => {
        navigate("/course/" + slug);
    }


    return (
        <div style={{ margin: '10px' }}>
            {
                courses.map((item, index) => {
                    return (
                        <div style={{ marginBottom: '10px', cursor: 'pointer' }} key={item._id}>
                            <Card onClick={()=>{
                                goToCourse(item.slug)
                            }}>
                                <CardHeader title={index + ". " + item.title} />
                                <CardContent>
                                    {/* {item.topic.name} <br/> */}
                                    {item.description}
                                </CardContent>
                            </Card>
                        </div>

                    )
                })
            }

            <Button fullWidth onClick={() => {
                setOpenCourse(true)
            }} variant="contained">Add Course</Button>

        </div>
    )
}
