import React, { useState } from 'react'
import Button from "@mui/material/Button";
import { Card, CardContent, CardHeader, Grid } from '@mui/material';

export default function Topic({ setOpenTopic, topics }) {


    return (
        <div style={{ margin: '10px' }}>
            {
                topics.map((item, index) => {
                    return (
                        <div style={{ marginBottom: '10px' }} key={item._id}>
                            <Card>
                                <CardHeader title={index + ". " + item.name} />
                                <CardContent>
                                    {item.description}
                                </CardContent>
                            </Card>
                        </div>

                    )
                })
            }

            <Button  fullWidth onClick={() => {
                setOpenTopic(true)
            }} variant="contained">Add Topic</Button>

        </div>
    )
}
