import React, { useState } from 'react'
import Button from "@mui/material/Button";
import { Card, CardContent, CardHeader, Grid } from '@mui/material';

export default function Content({ setOpenContent, contents }) {


    return (
        <div style={{ margin: '10px' }}>
            {
                contents.map((item, index) => {
                    return (
                        <div style={{ marginBottom: '10px' }} key={item._id}>
                            <Card>
                                <CardHeader title={index + ". [" + item.contentType+"]"} />
                                <CardContent>
                                    {item.title} <br/>
                                    <a href={item.link} target="_blank">Link</a> <br/>
                                    {item.description}
                                </CardContent>
                            </Card>
                        </div>

                    )
                })
            }

            <Button  fullWidth onClick={() => {
                setOpenContent(true)
            }} variant="contained">Add Content</Button>

        </div>
    )
}
